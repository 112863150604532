import { useContext, useEffect, useState } from "react";
import { Heading } from "../../components/typography/heading/Heading";
import { WeeklyHours } from "../../containers/availabilityManagement/weeklyHours/WeeklyHours";
import { DateOverrides } from "../../containers/availabilityManagement/dateOverrides/DateOverrides";
import { LastUpdate } from "../../containers/availabilityManagement/lastUpdate/LastUpdate";
import { CareNavDropdown } from "../../containers/availabilityManagement/careNavDropdown/CareNavDropdown";
import { Header } from "../../containers/availabilityManagement/header/Header";
import { Logout } from "../../containers/logout/Logout";
import { Option } from "../../lib/interfaces/input";
import { AlertBanner } from "../../components/alertBanner/AlertBanner";
import { ProviderInfo } from "../../lib/interfaces/user";
import {
  useAvailabilityManagement,
  FormattedAvailability,
} from "../../lib/hooks/useAvailabilityManagement";
import styles from "./style.module.css";
import { BaseContext } from "../../lib/context/context";
import { ExperianBypassCodeForm } from "../../components/experianBypassCodeForm/ExperianBypassCodeForm";
import { defaultProvider } from "../../lib/interfaces/user";
import { useAuthContext } from "../../contexts/AuthContext";

export const AvailabilityManagement = () => {
  const { userInfo } = useContext(BaseContext);
  const { signOut } = useAuthContext();

  const { getAvailability } = useAvailabilityManagement();

  const controller = new AbortController();

  const specialTitles = ["careNavigator", "triage", "medAssistant", "admin"];

  const [saved, setSaved] = useState(false);
  const [logout, setLogout] = useState(false);
  const [signedInUser, setSignedInUser] = useState<ProviderInfo>(
    userInfo || defaultProvider
  );
  const [selectedUser, setSelectedUser] = useState<Option>();
  const [availability, setAvailability] = useState<FormattedAvailability>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (userInfo) setSignedInUser(userInfo);
  }, [userInfo]);

  useEffect(() => {
    setSelectedUser({
      name: signedInUser.name,
      value: signedInUser.id,
    });
  }, [signedInUser]);

  useEffect(() => {
    if (selectedUser && selectedUser.value) {
      setLoading(true);

      getAvailability(selectedUser.value, controller).then(setAvailability);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (availability) setLoading(false);
  }, [availability]);

  const handleClickLogout = () => {
    setLogout(true);
  };

  const handleCancel = () => {
    setLogout(false);
  };

  const handleLogout = () => {
    signOut();
  };

  const handleOnSave = () => {
    //toggle saved to trigger useEffect hook in LastUpdate
    setSaved(!saved);
  };

  const handleSelectedUserChange = (selectedProviderOption: Option) => {
    setSelectedUser(selectedProviderOption);
  };

  return (
    <>
      <div>
        {typeof signedInUser.ehrId !== "undefined" &&
          signedInUser.ehrId !== "" && (
            <div>
              <AlertBanner
                type="info"
                message="Schedule Managed in Epic"
                withIcon={false}
              />
              <Header
                users={[signedInUser]}
                handleClickLogout={handleClickLogout}
              />
            </div>
          )}
        {(signedInUser.ehrId === "" ||
          typeof signedInUser.ehrId === "undefined") && (
          <>
            <div className={styles.availabilityPage}>
              <Header
                users={[signedInUser]}
                handleClickLogout={handleClickLogout}
              />
              <div className={styles.mainContent}>
                <div className={styles.availabilityManagement}>
                  <Heading className={styles.availabilityManagementHeader}>
                    {"Availability Management"}
                  </Heading>
                  <LastUpdate
                    saved={saved}
                    signedInUser={signedInUser}
                    availability={availability}
                  />
                </div>
                {signedInUser.providerType &&
                  specialTitles.includes(signedInUser.providerType) && (
                    <div>
                      <CareNavDropdown
                        signedInUser={signedInUser}
                        handleSelectedUserChange={handleSelectedUserChange}
                      />
                    </div>
                  )}
                <div className={styles.weeklyHoursAndOverride}>
                  <WeeklyHours
                    onSave={handleOnSave}
                    userId={selectedUser?.value}
                    availability={availability}
                    loading={loading}
                  />
                  <DateOverrides
                    onSave={handleOnSave}
                    userId={selectedUser?.value}
                    availability={availability}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
            <div className={styles.experianCodePanelWrapper}>
              <div className={styles.contentSeparator} />
              <ExperianBypassCodeForm />
            </div>
          </>
        )}
        {logout && (
          <Logout handleLogout={handleLogout} handleCancel={handleCancel} />
        )}
      </div>
    </>
  );
};
