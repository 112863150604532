import { useState, useEffect, useContext, useMemo } from "react";
import { NavigationTabs } from "../../components/navigationTabs/NavigationTabs";
import { IconProps } from "phosphor-react";
import { FeaturedIcon } from "../../components/featuredIcon/FeaturedIcon";
import { Heading } from "../../components/typography/heading/Heading";
import { Body } from "../../components/typography/body/Body";
import { Option } from "../../lib/interfaces/input";
import { SearchOption } from "../../lib/interfaces/listOfPatients";
import {
  Bell,
  CalendarBlank,
  WarningCircle,
  Check,
  CheckCircle,
  CalendarCheck,
  ProhibitInset,
} from "phosphor-react";
import { NotificationInterface } from "../../lib/interfaces/notifications";
import { Notification } from "../../components/notification/Notification";
import styles from "./style.module.css";
import { useHistory } from "react-router-dom";
import { useNotifications } from "../../lib/hooks/useNotifications";
import { BaseContext } from "../../lib/context/context";
import { format } from "date-fns";
import { Spinner } from "../../components/spinner/Spinner";
import _ from "lodash";

import { getLocalDateTime } from "../../lib/util/date";
import {
  CustomIcon,
  NewMemberIcon,
  CancelMemberIcon,
} from "../../components/customIcons/CustomIcons";
import { useChats } from "../../lib/hooks/useChats";
import { ProviderType } from "../../lib/apis/types/provider.types";
import PatientSearch from "../patient/PatientSearch";

const notificationRoutes = [
  { name: "All", value: "all" },
  { name: "Get Care Now", value: "getCareNow" },
  { name: "New Appt", value: "newAppt" },
  { name: "Cancelled Appt", value: "cancelledAppt" },
  { name: "Check-In", value: "checkIn" },
  { name: "Removed from Team", value: "removedFromTeam" },
  { name: "New Member", value: "membershipUpgrade" },
  { name: "Member Cancellation", value: "membershipCancelled" },
];

export interface NotificationsProps {}

const notificationTypes = [
  "cancelledAppt",
  "getCareNow",
  "checkIn",
  "carePlanComplete",
  "removedFromTeam",
  "newAppt",
  "membershipUpgrade",
  "membershipCancelled",
];

interface notificationStyleInterface {
  [key: string]: {
    color: string;
    Icon:
      | React.ForwardRefExoticComponent<
          IconProps & React.RefAttributes<SVGSVGElement>
        >
      | CustomIcon;
    buttonText: string;
  };
}

const notificationStyle: notificationStyleInterface = {
  cancelledAppt: {
    color: "gray",
    Icon: CalendarBlank,
    buttonText: "View Patient",
  },
  getCareNow: {
    color: "danger",
    Icon: WarningCircle,
    buttonText: "View Message",
  },
  checkIn: {
    color: "purple",
    Icon: Check,
    buttonText: "View Patient",
  },
  carePlanComplete: {
    color: "success",
    Icon: CheckCircle,
    buttonText: "View Patient",
  },
  removedFromTeam: {
    color: "gray",
    Icon: ProhibitInset,
    buttonText: "View Patient",
  },
  newAppt: {
    color: "gray",
    Icon: CalendarCheck,
    buttonText: "View Patient",
  },
  membershipUpgrade: {
    color: "gray",
    Icon: NewMemberIcon,
    buttonText: "View Patient",
  },
  membershipCancelled: {
    color: "gray",
    Icon: CancelMemberIcon,
    buttonText: "View Patient",
  },
};

export const Notifications = ({}: NotificationsProps) => {
  const history = useHistory();
  const { userInfo, allProviders, openPopoutChat } = useContext(BaseContext);
  const { getProviderNotifs, getAllNotifs } = useNotifications();

  const { getUserChat } = useChats();

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>("all");
  const [notifications, setNotifications] = useState<NotificationInterface[]>();
  const [selectedSearchOption, setSelectedSearchOption] =
    useState<SearchOption>({ patient: { name: "", value: "" } });

  const getMinDate = () => {
    const minDate = new Date();
    minDate.setUTCHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() - 7);
    return minDate;
  };

  useEffect(() => {
    if (userInfo && allProviders) {
      const minDate = getMinDate();
      userInfo.providerType !== ProviderType.CareCoordinator &&
      userInfo.providerType !== ProviderType.SiteAdmin
        ? getProviderNotifs(userInfo.id, minDate, controller).then(
            setNotifications,
          )
        : getAllNotifs(minDate, controller).then(setNotifications);
    }
  }, [userInfo, allProviders]);

  useEffect(() => {
    if (notifications) setLoading(false);
  }, [notifications]);

  const handleSelectedPatientsChange = (selectedPatient: Option) => {
    setSelectedSearchOption({
      patient: selectedPatient,
    });
  };

  const filterByType = (notif: NotificationInterface): boolean => {
    if (activeTab === "all") {
      return true;
    } else if (activeTab) {
      return activeTab === notif.name;
    } else return true;
  };

  const filterByPatient = (notif: NotificationInterface): boolean => {
    if (selectedSearchOption.patient.value) {
      return notif.subjectId === selectedSearchOption.patient.value;
    } else return true;
  };

  const onTabChange = (value: string) => {
    setActiveTab(value);
  };

  const onDeleteNotif = (notifId: string) => {
    const notifs = JSON.parse(JSON.stringify(notifications));
    _.remove(notifs, (notif: NotificationInterface) => notif.id === notifId);
    setNotifications(notifs);
  };

  const onViewMessageClick = async (userId: string, chatId: string) => {
    // history.push({ pathname:`messages`, state: {selectedChatId: chatId} })
    const chat = await getUserChat(userId, "patient", chatId, controller);

    if (chat) {
      openPopoutChat &&
        openPopoutChat({
          id: chatId,
          participants: chat[0].participants,
          regarding: chat[0].regarding,
        });
    }
  };

  const onViewPatientClick = (patientId: string, name?: string) => {
    const targetPath =
      name === "memberInsuranceValidation" ||
      name === "memberIdentificationValidation"
        ? `record`
        : `careplan`;
    history.push(`patient/${patientId}/${targetPath}`);
  };

  const getFriendlyDate = (timeStamp: string) => {
    return format(getLocalDateTime(timeStamp), "p MM/dd/yy");
  };

  const sortByTimeAndType = (
    n1: NotificationInterface,
    n2: NotificationInterface,
  ) => {
    if (n1.name === "getCareNow" && n2.name !== "getCareNow") return -1;
    else if (n1.name !== "getCareNow" && n2.name === "getCareNow") return 1;
    else {
      const date1 = getLocalDateTime(n1.createdOn);
      const date2 = getLocalDateTime(n2.createdOn);

      if (date1 > date2) return -1;
      else return 1;
    }
  };

  const filteredNotifs = useMemo(() => {
    if (notifications)
      return notifications
        .filter(filterByPatient)
        .filter(filterByType)
        .sort(sortByTimeAndType);
  }, [notifications, activeTab, selectedSearchOption]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Heading type="02">Notifications</Heading>
        <div className={styles.searchAndFilter}>
          <PatientSearch
            placeholder="All Patients"
            width={400}
            className={styles.search}
            handlePatientsChange={handleSelectedPatientsChange}
            value={selectedSearchOption.patient}
            isPatientSearchPage={false}
          />
        </div>
        <div className={styles.navContainer}>
          <NavigationTabs
            routes={notificationRoutes}
            value={activeTab}
            onChange={onTabChange}
          />
        </div>
      </div>
      <div className={styles.notifs}>
        {!loading ? (
          filteredNotifs && filteredNotifs.length > 0 ? (
            <div>
              {filteredNotifs.map((notification, index) => {
                const type = notification.name;
                return (
                  <Notification
                    key={notification.id}
                    id={notification.id}
                    //@ts-ignore
                    backgroundColor={
                      notificationTypes.includes(type)
                        ? notificationStyle[type].color
                        : "gray"
                    }
                    //@ts-ignore
                    Icon={
                      notificationTypes.includes(type)
                        ? notificationStyle[type].Icon
                        : Bell
                    }
                    //@ts-ignore
                    iconColor={
                      notificationTypes.includes(type)
                        ? notificationStyle[type].color
                        : "gray"
                    }
                    title={notification.title}
                    caption={notification.description || ""}
                    creator={notification.creator}
                    timestamp={getFriendlyDate(notification.createdOn)}
                    buttonLabel={
                      type === "getCareNow" ? "View Message" : "View Patient"
                    }
                    buttonOnClick={async () => {
                      type === "getCareNow"
                        ? await onViewMessageClick(
                            notification.subjectId,
                            notification.resourceId || "",
                          )
                        : onViewPatientClick(
                            notification.subjectId,
                            notification.name,
                          );
                    }}
                    xOnClick={() => {
                      onDeleteNotif(notification.id);
                    }}
                    className={
                      index !== filteredNotifs.length - 1 ? styles.notif : ""
                    }
                  />
                );
              })}
            </div>
          ) : (
            <div className={styles.emptyState}>
              <FeaturedIcon Icon={Bell} type="gray" />
              <div className={styles.noNotifs}>
                {" "}
                <Heading type="02">No Notifications to Display</Heading>{" "}
              </div>
              <Body color="secondary">
                When you receive a notification, it will appear here.
              </Body>
            </div>
          )
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};
