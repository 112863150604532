import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, Route, useLocation } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import ddConfig from "./config/datadog.config";
import "./config/tracing.ts";
import { VideoCall } from "./pages/videoChat/VideoCall";
import { Base } from "./containers/_base/Base";
import LoginRedirect from "./containers/login/LoginRedirect";
import { AuthContextProvider } from "./contexts/AuthContext";

const env = process.env.REACT_APP_DD_ENV;
const isTracingEnabled = !(env === "local" || env === "dev");

if (isTracingEnabled) {
  // initialize DataDog RUM && Session Replay
  datadogRum.init(ddConfig.rum);
  // initialize DataDog Logging
  datadogLogs.init(ddConfig.logs);
}

export default function App() {
  const location = useLocation();
  const [authError, setAuthError] = useState<string | null>(null);

  const queryClient = new QueryClient();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      setAuthError(decodeURIComponent(errorDescription));
    }
  }, [location]);

  return (
    <AuthContextProvider authError={authError}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/login" component={LoginRedirect} />
          <Route path="/test">here</Route>
          <Route path={`/video-chat/:visitId/:userId`} component={VideoCall} />
          <Route path="/" component={Base} />
        </Switch>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}
